<template>
  <b-container>
    <b-col cols="12" class="mt-2">
      <b-table
        hover
        responsive
        :items="linesCortinas"
        :fields="columnsTable"
        class="table-summary"
        caption-top
      >
        <template #table-caption>
          {{ $t("orders.curtains") }}
        </template>
        <template #cell(id)="data">
          <span style="width: 30px">{{ data.item.id }}</span>
        </template>
        <template #cell(description)="data">
          <span style="width: 30px">{{ data.item.description }}</span>
        </template>
        <template #cell(atributos)="data">
          <p class="mb-25">
            {{ data.item.attr.l1 }}mm x {{ data.item.attr.h1 }}mm
          </p>
          <p class="mb-0">
            <span
              v-if="
                data.item.attr.cajon &&
                (data.item.product_object.ref == 'MFBD E-120' ||
                  data.item.product_object.ref == 'MSB DH-60/DA-150 Doble')
              "
            >
              {{ data.item.attr.cajon }}
            </span>
            {{ data.item.attr.motor }}
            <span v-if="data.item.attr.guias"
              >- {{ data.item.attr.guias }}</span
            >
          </p>
        </template>
        <template #cell(product_object)="data">
          <span v-if="data.item.product_object">{{
            data.item.product_object.name[currentLanguage]
          }}</span>
        </template>
        <template #cell(cuadro)="data">
          {{ cuadro(data.item.id) }}
        </template>
        <template #cell(extra)="data">
          {{ extra(data.item.id) }}
        </template>
        <template #cell(amount)="data">
          <!--{{ //priceFormatted(data.item.amount) }} €-->
          {{
            priceFormatted(
              (data.item.amount - (data.item.amount * order.discount) / 100) *
                parseFloat(data.item.quantity)
            )
          }}
          €
        </template>
        <template #cell(total)="data">
          {{
            priceFormatted(
              (data.item.amount - (data.item.amount * order.discount) / 100) *
                parseFloat(data.item.quantity)
            )
          }}
          €
        </template>
        <template #cell(actions)="data" v-if="actionsShow">
          <span>
            <span
              class="text-danger cursor-pointer"
              @click="actionDeleteLine(data.item.id, data.item.type)"
            >
              <feather-icon icon="TrashIcon" />
            </span>
          </span>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12" class="mt-2">
      <b-table
        hover
        responsive
        :items="linesCuadros"
        :fields="columnsCuadroFunc"
        class="table-summary"
        caption-top
      >
        <template #table-caption>
          {{ $t("orders.control_panels") }}
        </template>
        <template #cell(id)="data">
          <span style="width: 30px">{{ data.item.id }}</span>
        </template>
        <template #cell(description)="data">
          <span style="width: 30px">{{ data.item.description }}</span>
        </template>
        <template #cell(product_object)="data">
          <span v-if="data.item.product_object">{{
            data.item.product_object.name[currentLanguage]
          }}</span>
          <span v-if="data.item.attr.modelo">
            - {{ data.item.attr.modelo }}</span
          >
        </template>
        <template #cell(amount)="data">
          {{ priceFormatted(data.item.amount) }} €
        </template>
        <template #cell(total)="data">
          {{
            priceFormatted(data.item.amount * parseFloat(data.item.quantity))
          }}
          €
        </template>
        <template #cell(actions)="data">
          <span>
            <span
              class="text-danger cursor-pointer"
              @click="actionDeleteLine(data.item.id, data.item.type)"
            >
              <feather-icon icon="TrashIcon" />
            </span>
          </span>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12" class="mt-2">
      <b-table
        hover
        responsive
        :items="linesExtras"
        :fields="columnsExtraFunc"
        class="table-summary"
        caption-top
      >
        <template #table-caption>
          {{ $t("orders.additional_features") }}
        </template>
        <template #cell(id)="data">
          <span style="width: 30px">{{ data.item.id }}</span>
        </template>
        <template #cell(description)="data">
          <span style="width: 30px">{{ data.item.description }}</span>
        </template>
        <template #cell(atributos)="data">
          <span v-if="data.item.attr.comments">{{
            data.item.attr.comments
          }}</span>
        </template>
        <template #cell(product_object)="data">
          <span v-if="data.item.product_object">{{
            data.item.product_object.name[currentLanguage]
          }}</span>
        </template>
        <template #cell(amount)="data">
          {{ priceFormatted(data.item.amount) }} €
        </template>
        <template #cell(total)="data">
          {{
            priceFormatted(data.item.amount * parseFloat(data.item.quantity))
          }}
          €
        </template>
        <template #cell(actions)="data">
          <span>
            <span
              class="text-danger cursor-pointer"
              @click="actionDeleteLine(data.item.id, data.item.type)"
            >
              <feather-icon icon="TrashIcon" />
            </span>
          </span>
        </template>
      </b-table>
    </b-col>
    <!-- line and space -->
    <b-col cols="12">
      <hr />
    </b-col>
    <!-- factura -->
    <b-col cols="12" class="resume_total">
      <div class="d-flex">
        <div style="width: 80%"></div>
        <div class="text-right" style="width: 9%">
          <p>
            <strong>{{ $t("orders.addition") }}</strong>
          </p>
        </div>
        <div class="text-right" style="width: 10%">
          <p class="text-right">{{ priceFormatted(order.amount) }} €</p>
        </div>
        <div style="width: 5%" v-if="actionsShow"></div>
      </div>
      <template v-if="showSpecialPrices">
        <div v-if="showFacility" class="d-flex">
          <div style="width: 80%"></div>
          <div class="text-right" style="width: 9%">
            <p><strong>{{ $t('facility') }}</strong></p>
          </div>
          <div class="text-right" style="width: 10%">
            <p class="text-right">{{ priceFormatted(order.installation_costs) }} €</p>
          </div>
          <div style="width: 5%" v-if="actionsShow"></div>
        </div>
        <div v-if="showDiscount" class="d-flex">
          <div style="width: 80%"></div>
          <div class="text-right" style="width: 9%">
            <p><strong>{{ $t('global_discount') }}</strong></p>
            <p class="text-right">{{ order.discount_revision_percentage || 0 }} %</p>
          </div>
          <div class="text-right" style="width: 10%">
            <p><strong>{{ $t('import') }}</strong></p>
            <p class="text-right">{{ priceFormatted(order.discount_revision) }} €</p>
          </div>
          <div style="width: 5%" v-if="actionsShow"></div>
        </div>
      </template>
      <div class="d-flex">
        <div class="d-flex justify-content-end" style="width: 89%">
          <div>
            <b-button
              v-if="order.packaging_standard && role !== 'super_admin' && $route.name === 'steps'"
              size="sm"
              class="change-button mr-1"
              @click="changePackaging"
            >
              {{ order.packaging_type === 'packaging_standard' ?
                  $t("change_wood") : $t("change_standard")
              }}
            </b-button>
          </div>
          <p><strong>{{order.packaging_type === 'packaging_standard' ? $t('standard_packaging') : $t('wood_packaging') }}</strong></p>
        </div>
        <div class="text-right" style="width: 10%">
          <p class="text-right">
            {{ order.packaging_type === 'packaging_standard' ? priceFormatted(order.packaging_standard) : priceFormatted(order.packaging_wood) }}
            €
          </p>
        </div>
        <div style="width: 5%" v-if="actionsShow"></div>
      </div>
      <div class="d-flex">
        <div style="width: 80%"></div>
        <div class="text-right" style="width: 9%">
          <p><strong>{{ $t('delivery') }}</strong></p>
        </div>
        <div class="text-right" style="width: 10%">
          <p class="text-right">{{ priceFormatted(order.shipping_costs) }} €</p>
        </div>
        <div style="width: 5%" v-if="actionsShow"></div>
      </div>
      <div class="d-flex" v-if="order.amount_iva">
        <div style="width: 80%"></div>
        <div class="text-right" style="width: 9%">
          <p><strong>IVA (21%)</strong></p>
        </div>
        <div class="text-right" style="width: 10%">
          <p class="text-right">{{ priceFormatted(order.amount_iva) }} €</p>
        </div>
        <div style="width: 5%" v-if="actionsShow"></div>
      </div>
      <div class="d-flex">
        <div style="width: 80%"></div>
        <div class="text-right" style="width: 9%">
          <p><strong>Total</strong></p>
        </div>
        <div class="text-right" style="width: 10%">
          <p class="text-right">{{ priceFormatted(order.total_amount) }} €</p>
        </div>
        <div style="width: 5%" v-if="actionsShow"></div>
      </div>
    </b-col>

    <!-- linesCortinas extra data-->
    <b-col cols="12">
      <hr/>
    </b-col>
    <b-col class="d-flex ml-1" cols="12">
      <p class="font-medium-3 mr-1">
        {{ $t('orders.system_info') }}
      </p>
      <b-link @click="collapseSystemInfo = !collapseSystemInfo">
        <FeatherIcon v-if="!collapseSystemInfo" icon="ChevronDownIcon" />
        <FeatherIcon v-else icon="ChevronUpIcon" />
      </b-link>
    </b-col>
    <b-collapse v-model="collapseSystemInfo">
      <b-col cols="12" class="mt-2">
        <b-table
          hover
          responsive
          :items="linesCortinas"
          :fields="columnsData"
          class="table-summary"
          caption-top
        >
        </b-table>
      </b-col>
    </b-collapse>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BTable, BContainer, BCol, BRow } from "bootstrap-vue";

export default {
  name: "Tables",
  components: {
    BRow,
    BCol,
    BTable,
    BContainer,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    linesCortinas: {
      type: Array,
      required: true,
    },
    linesCuadros: {
      type: Array,
      required: true,
    },
    linesExtras: {
      type: Array,
      required: true,
    },
    actionsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapseSystemInfo: false,
      descuentoTotal: 0,
      columnsLines: [
        {
          label: "ID",
          key: "type",
          thStyle: { width: "2.5%" },
        },
        {
          label: this.$t("Descripcion"),
          key: "product_object",
          thStyle: { width: "22%" },
        },
        {
          label: this.$t("orders.characteristics"),
          key: "atributos",
          thStyle: { width: "26%" },
        },
        {
          label: this.$t("orders.control_panel"),
          key: "cuadro",
          thStyle: { width: "12%" },
        },
        {
          label: this.$t("orders.additional_features"),
          key: "extra",
          thStyle: { width: "12%" },
        },
        {
          label: this.$t("orders.units"),
          key: "quantity",
          thStyle: { width: "2.5%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: this.$t("Precio"),
          key: "amount",
          thStyle: { width: "9%" },
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          label: "Total",
          key: "total",
          thStyle: { width: "9%" },
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      columnsCuadro: [
        {
          label: "ID",
          key: "type",
          thStyle: { width: "5%" },
        },
        {
          label: this.$t("Descripcion"),
          key: "product_object",
          thStyle: { width: "69%" },
        },
        {
          label: this.$t("orders.units"),
          key: "quantity",
          thStyle: { width: "6%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: this.$t("Precio"),
          key: "amount",
          thStyle: { width: "9%" },
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          label: "Total",
          key: "total",
          thStyle: { width: "9%" },
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      columnsExtras: [
        {
          label: "ID",
          key: "type",
          thStyle: { width: "5%" },
        },
        {
          label: this.$t("Descripcion"),
          key: "product_object",
          thStyle: { width: "22%" },
        },
        {
          label: this.$t("orders.characteristics"),
          key: "atributos",
          thStyle: { width: "48.5%" },
        },
        {
          label: this.$t("orders.units"),
          key: "quantity",
          thStyle: { width: "6%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: this.$t("price"),
          key: "amount",
          thStyle: { width: "9%" },
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          label: "Total",
          key: "total",
          thStyle: { width: "9%" },
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      columnsData: [
        {
          label: this.$t("orders.system"),
          key: "product_object.modelo.es",
        },
        {
          label: "ID",
          key: "type",
        },
        {
          label: "L1",
          key: "attr.l1",
        },
        {
          label: "H1",
          key: "attr.h1",
        },
        {
          label: "L2",
          key: "attr.l2",
        },
        {
          label: "H2",
          key: "attr.h2",
        },
        {
          label: this.$t("orders.drawer"),
          key: "attr.cajon",
        },
        {
          label: this.$t("orders.guides"),
          key: "attr.guias",
        },
        {
          label: this.$t("orders.motor_units"),
          key: "attr.num_motor",
        },
        {
          label: "POS",
          key: "attr.position",
        },
        {
          label: "PCS",
          key: "quantity",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      role: "auth/getRole",
      currentLanguage: 'languages/getCurrentLanguage'
    }),
    columnsTable() {
      const columns = this.columnsLines;
      if (this.actionsShow) {
        columns.push({
          label: "",
          key: "actions",
        });
      }
      return columns;
    },
    columnsCuadroFunc() {
      const columns = this.columnsCuadro;
      if (this.actionsShow) {
        columns.push({
          label: "",
          key: "actions",
        });
      }
      return columns;
    },
    columnsExtraFunc() {
      const columns = this.columnsExtras;
      if (this.actionsShow) {
        columns.push({
          label: "",
          key: "actions",
        });
      }
      return columns;
    },
    showSpecialPrices() {
      const wasUnderReview = this.order.status.some(({ type }) => type === 'revision')
      return  this.role === 'super_admin' ?
        this.order.status_last.type === 'revision' && wasUnderReview :
        this.order.status_last.type !== 'revision' && wasUnderReview
    },
    showDiscount() {
      return  this.role === 'super_admin' ?
        true :
        this.order.discount_revision_percentage > 0
    },
    showFacility() {
      return  this.role === 'super_admin' ?
        true :
        this.order.installation_costs > 0
    }
  },
  methods: {
    ...mapActions({
      deleteLine: "orders/deleteLine",
      getOrder: "steps/getOrder",
      changeTypePackaging: "orders/changePackaging",
      getCheckControlBox: 'orders/checkControlBox',
    }),
    async changePackaging() {
      const type = this.order.packaging_type === 'packaging_standard' ? 'packaging_wood' : 'packaging_standard'

      await this.changeTypePackaging({ order:this.order.id, type })
      await this.getOrder()

    },
    actionDeleteLine(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t("orders.modal_delete_line", { name }), {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: this.$t("Eliminar"),
          cancelTitle: this.$t("Cancelar"),
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.controlDelete(id);
            // location.reload()
          }
        });
    },
    async controlDelete(id) {
      await this.deleteLine(id);
      await this.getCheckControlBox(this.order.id)
      await this.getOrder();
    },
    cuadro(id) {
      let output = "";
      this.linesCuadros.forEach((c) => {
        if (c.attr.cortinas) {
          c.attr.cortinas.forEach((x) => {
            // console.log(x)
            if (id === x.id) {
              output = c.type;
            }
          });
        }
      });
      return output;
    },
    extra(id) {
      // console.log('this.linesExtras', this.linesExtras)
      let output = "";
      this.linesExtras.forEach((c) => {
        if (c.attr.cortinas) {
          c.attr.cortinas.forEach((x) => {
            // console.log(x)
            if (id === x.id) {
              if (output != "") {
                output = output + "-" + c.type;
              } else {
                output = c.type;
              }
            }
          });
        }
      });
      return output;
    },
    priceFormatted(price) {
      let val = (price / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  async created() {
    if (this.order.discount) {
      this.linesCortinas.forEach(function (line) {
        this.descuentoTotal += (line.amount * this.order.discount) / 100;
      }, this);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.change-button {
  padding: 5px 15px;
}

</style>
